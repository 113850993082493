import React from "react";
import steinLook from "../Images/stein_look.png";
function EmailBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={steinLook} alt="stein_look.png" />
      </div>
      <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        <h2>BE ON THE LOOK OUT!</h2>
        Expect an email within 5 business days from{" "}
        <a href="mailto:notification@prepaiddigitalsolutions.com">
          {process.env.REACT_APP_PREP}
        </a>{" "}
        or <br />
        <a href="mailto:no-reply@hawkmarketplace.com">
          {process.env.REACT_APP_MARKET_PLACE}
        </a>{" "}
        - we suggest adding these email addresses to your SAFE SENDER list in
        your email settings.
      </div>
    </div>
  );
}

export default EmailBox;
